import React, {useState} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';
import ContentWyswyg from '../components/content-wyswyg';
import Feature from '../components/feature';

import avatarLionel from '../images/avatar-lionel.jpg';
import avatarVincent from '../images/avatar-vincent.jpg';

import visualOurValues from '../images/about-us-values.svg';

const About = () => {
const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
  type: null,
  bp: null
});

return (
  <Layout
    socialDark={true}
    currentPage='about'
    updateBreakpoint={setCurrentBreakpointChildren}
  >
    <SEO title="About" />

    <Section>
      <ContentHero
        visual="20190204_Profile-Pictures-VinceVanGrootel-016.jpg"
        title="About us"
        text="<p>We are two simple humans who love building inspiring products.</p>"
      />
    </Section>
    <Section>
      <ContentWyswyg
        content={[
          {
            type: 'text',
            html: `
              <p class="intro">We started Ludus a few years ago because we were frustrated with existing presentation tools, not allowing you to collaborate efficiently, and limiting you from a creative point of view. We are proud that what we initially built to solve our own problems is now helping thousands of teams all around the world.</p>
            `
          },
          {
            type: 'people',
            cards: [
              {
                avatar: avatarLionel,
                name: 'Lionel Cordier',
                title: 'Co-Founder & CEO',
                links: {
                  linkedin: 'https://www.linkedin.com/in/lionelcordier/',
                  twitter: 'https://twitter.com/blobinabottle'
                }
              },
              {
                avatar: avatarVincent,
                name: 'Vincent Battaglia',
                title: 'Co-Founder & CTO',
                links: {
                  linkedin: 'https://www.linkedin.com/in/vinch/',
                  twitter: 'https://twitter.com/vinchbat'
                }
              }
            ]
          }
        ]}
      />
    </Section>
    <Section>
      {(currentBreakPoint.type === 'desktop') ? (
        <div style={{
          position: 'absolute',
          zIndex: '1',
          width: '45%',
          paddingBottom: '45%',
          bottom: '-1px',
          left: '0',
          background: `transparent url(${visualOurValues}) no-repeat 0 50%`,
          backgroundSize: 'contain',
        }} />
      ) : null}
      
      <Feature 
        reverse={true}
        title="We believe Ludus is much more than a presentation tool"
        textHtml={`
          <p>For most people, a presentation is basically a marketing material. But to us, it’s a tool to communicate, to collaborate, to learn, and to play. And we believe it should be fun to build.</p>
          <p>We think Ludus, should be the playground for your imagination. A place where you create and share the most incredible stories, with a little magic touch. We’ll always do our best to make you shine, because at the end what truly matters is what you’re saying, not the tool you’re using.</p>
        `}
        url="/all-features"
        link="See everything"
      />
    </Section>

    
  </Layout>
)
}

export default About
